import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userAvatar'
})
export class UserAvatarPipe implements PipeTransform {

  transform(name: string): string {
    return name ? name.charAt(0).toUpperCase() : 'A';
  }
}
