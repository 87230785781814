import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthService} from "../modules/auth";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.initService();
  }

  initService() {
    const authData = this.auth.getAuthLocal();
    if (!authData || !authData.token) {
      return of(undefined);
    }

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authData.token}`,
    });
  }

  formatDate(date: Date): string {
    return date.toISOString().split('T')[0]; // "2025-12-01"
  }

  getUser(user_id:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/users/${user_id}`,{
      headers: this.httpHeaders,
    });
  }
  getUserForClient(user_id:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/users/for-client/${user_id}`,{
      headers: this.httpHeaders,
    });
  }

  getClients(data:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/clients`, data,{
      headers: this.httpHeaders,
    });
  }

  getChinaUsaClients(data:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/wh-usa-deals/clients`, data,{
      headers: this.httpHeaders,
    });
  }

  getAllClients(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/clients`,{
      headers: this.httpHeaders,
    });
  }

  getAllClientsShortData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/clients/getAllClients`,{
      headers: this.httpHeaders,
    });
  }

  getClientsReport(options?: object): Observable<any> {
    let params = new HttpParams();

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        if (this.isInvalidValue(value)) return;

        if (Array.isArray(value)) {
          (value as any[]).forEach((id) => {
            params = params.append(`${key}[]`, id);
          });
        } else if (value instanceof Date) {
          params = params.set(key, this.formatDate(value));
        } else {
          params = params.set(key, String(value));
        }
      });
    }


    return this.http.get(`${environment.apiUrl}/reports/clients`, {
      headers: this.httpHeaders,
      params: params,
    })
  }

  getClientsReportCSV(options?: object): Observable<Blob> {
    let params = new HttpParams();

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        if (this.isInvalidValue(value)) return;

        if (Array.isArray(value)) {
          (value as any[]).forEach((id) => {
            params = params.append(`${key}[]`, id);
          });
        } else if (value instanceof Date) {
          params = params.set(key, this.formatDate(value));
        } else {
          params = params.set(key, String(value));
        }
      });
    }


    return this.http.get(`${environment.apiUrl}/reports/clients`, {
      headers: this.httpHeaders,
      params: params,
      responseType: 'blob',
    })
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users`, {
      headers: this.httpHeaders,
    });
  }

  getManagers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/managers`, {
      headers: this.httpHeaders,
  });
}

  makeNewUser(data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/users/new`, data, {
      headers: this.httpHeaders,
    });
  }

  makeNewClient(data:any): Observable<any>{
    console.log(data)
    return this.http.post(`${environment.apiUrl}/clients/new`, data, {
      headers: this.httpHeaders,
    });
  }

  editClient(data:any, id:number): Observable<any>{
    return this.http.post(`${environment.apiUrl}/clients/update/${id}`, data, {
      headers: this.httpHeaders,
    })
  }

  editUser(data:any, id:number): Observable<any>{
    return this.http.patch(`${environment.apiUrl}/users/${id}`, data, {
      headers: this.httpHeaders,
    })
  }

  removeUser(id:number,data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/users/${id}/remove`, data,{
      headers: this.httpHeaders,
    });
  }
  removeClient(id:number): Observable<any>{
    return this.http.delete(`${environment.apiUrl}/clients/${id}`, {
      headers: this.httpHeaders,
    });
  }

  changePassword(data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/auth/change-password`, data,{
      headers: this.httpHeaders,
    });
  }
  uploadFile(id:any,data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/clients/${id}/uploadFile`,data,{
      headers: this.httpHeaders,
    });
  }
  deleteFile(id:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/clients/${id}/deleteFile`,{},{
      headers: this.httpHeaders,
    });
  }
  sendResetEmail(id:any){
    return this.http.post(`${environment.apiUrl}/users/${id}/email_password`,{},{
      headers: this.httpHeaders,
    });
  }

  private isInvalidValue(value: any): boolean {
    return value === undefined || value === null || value === '';
  }
}
