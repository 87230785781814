<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 ">
  <div
    id="kt_toolbar_container"
    [ngClass]="toolbarContainerCssClasses"
    class="app-container container-xxl d-flex flex-stack "
  >
    <app-page-title
      #ktPageTitle
      class="page-title d-flex"
      [ngClass]="pageTitleCssClasses"
    ></app-page-title>
    <!-- begin::Actions  -->
  <!--  <div class="d-flex align-items-center py-1">-->
  <!--    &lt;!&ndash; begin::Wrapper  &ndash;&gt;-->
  <!--    <div class="me-4">-->
  <!--      &lt;!&ndash; begin::Menu  &ndash;&gt;-->
  <!--      <a-->
  <!--        class="-->
  <!--          btn btn-sm btn-flex btn-light btn-active-primary-->
  <!--          fw-bolder-->
  <!--          cursor-pointer-->
  <!--        "-->
  <!--        data-kt-menu-trigger="click"-->
  <!--        data-kt-menu-placement="bottom-end"-->
  <!--        data-kt-menu-flip="top-end"-->
  <!--      >-->
  <!--        <span-->
  <!--          [inlineSVG]="'./assets/media/icons/duotune/general/gen031.svg'"-->
  <!--          class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"-->
  <!--        ></span>-->
  <!--        Filter-->
  <!--      </a>-->

  <!--      &lt;!&ndash; end::Menu  &ndash;&gt;-->
  <!--    </div>-->
  <!--    &lt;!&ndash; end::Wrapper  &ndash;&gt;-->

  <!--    &lt;!&ndash; begin::Button  &ndash;&gt;-->
  <!--    <a-->
  <!--      class="btn btn-sm btn-primary cursor-pointer"-->
  <!--      data-bs-toggle="modal"-->
  <!--      data-bs-target="#kt_modal_create_app"-->
  <!--      id="kt_toolbar_primary_button"-->
  <!--    >-->
  <!--      Create-->
  <!--    </a>-->
  <!--    &lt;!&ndash; end::Button  &ndash;&gt;-->
  <!--  </div>-->
    <!-- end::Actions  -->
  </div>
</div>
