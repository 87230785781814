// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      CLIENTS: 'Clients',
      DEALS: 'Deals',
      DOCS: 'Documents',
      INVOICES: 'Invoices',
      USERS: 'Users',
      ROLES: 'Roles',
      SETTINGS: 'Settings',
      SETTINGS_SYS: 'System settings',
      SHIPMENTS: 'Shipments',
      SEA_LIST: 'Sea',
      AVIA_LIST: 'Avia',
      CLIENT_DEALS: 'My deals',
      JOBS: 'Tasks',
      TASKS:'Tasks',
      FILES:'Files',
      BOARD: 'Board',
      TOOLS: {
        TITLE: 'Tools',
        INSPECTION_TOOL: 'Inspection calculator'
      },
      REPORTS: {
        TITLE: 'Reports',
        CLIENTS_REPORT: 'Clients report',
        DEALS_REPORT: 'Deals report',
      }
    },
    APP_USER_MENU: {
      PROFILE: 'Profile',
      DEALS: 'My deals',
      LANGUAGE: 'Language',
      LOGOUT: 'Logout',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account? ',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        WAIT: 'Please wait...',
        GUEST_IN: 'Sign in as guest'
      },
      LOGIN: {
        TITLE: 'Sign in',
        BUTTON: 'Sign In',
        TITLE_TEXT: 'Your Personal Account',
      },
      FORGOT: {
        TITLE: 'Forgot Password?',
        DESC: 'Enter your email',
        SENT:"Reset link was sent to you email",
        SUCCESS: 'Your account has been successfully reset.'
      },
      RESET:{
        TITLE:"Change password",
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        ALREADY_HAVE:'Already have an account?',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: 'Data is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        INVALID_EMAIL:'Invalid email',
        PASSWORD_NOT_EQUAL:"Password and confirmation are not equal"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    CLIENTS: {
      SEARCH_CUSTOMERS: 'Search clients',
      ADD_CUSTOMER: 'New client',
      CUSTOMER_NAME: 'Client name',
      CUSTOMER_EMAIL: 'E-mail',
      CUSTOMER_COMPANY: 'Company',
      CUSTOMER_PHONE: 'Phone',
      CUSTOMER_STATUS: 'Validation Status',
      CUSTOMER_DETAIL: 'Client',
      CUSTOMER_INFO_TAB: 'Details',
      CUSTOMER_DEALS_TAB: 'Deals',
      CUSTOMER_ACTIVITY_TAB: 'Activity',
      CUSTOMER_ADD_DEAL: 'Deal',
      CUSTOMER_ADD_JOB: 'Task',
      CUSTOMER_WORK_REGION: 'Region',
      CUSTOMER_ADDRESS: 'State / Province / City / Address',
      CLIENTS_TAB_SHIPMENTS: 'Shipments',
      CLIENTS_TAB_INFO: 'Info',
      TABS_DETAILS:{
        WORK_STATUS: 'Work Status',
        FB_STATUS: 'Facebook status',
        POSITION: 'Brand',
        COMPANY: 'Company name',
        REF_PATH: 'Reference way',
        DELIVERY: 'Delivery way',
        PARTNER_FB: 'Facebook partner',
        MANAGER: 'Manager',
        ADVISER: 'Adviser',
        TELEGRAM:'Telegram',
        MOBILE:'Mobile',
        EMAIL:'Email',
        ADDRESS:'Address'
      },
      FORM:{
        CLIENT_EDIT_TITLE: 'Add / Edit client',
        CLIENT_AVATAR: 'Picture/Avatar',
        CLIENT_F_L_NAME: 'First and Last Name',
        CLIENT_ADDITIONAL_PHONE: 'Additional Phone',
        CLIENT_MANAGER: 'Manager',
        CLIENT_ADVISER: 'Adviser',
        CLIENT_AFFILIATE_TYPE: 'Affiliate type',
        CLIENT_POSITION: 'Brand',
        CLIENT_ADDITIONAL_INFO: 'Additional information (any text data not specified in the fields above)'
      },
      NO_FILES:'No files',
      DELETE_CLIENT: {
        TITLE: 'Delete client',
        ATTENTION: 'Attention!!',
        TEXT: 'Are you sure you want to delete this client?',
      },
      CLIENT_DEALS:{
        MY_MANAGER:"My manager"
      }
    },
    DEALS: {
      SEARCH_DEAL: 'Search deals',
      DEAL_ADD: 'Add deal',
      DEAL_ID_OR_NAME: 'Name/id deal',
      DEAL_ID: 'Deal id',
      DEAL_CLIENT_NAME: 'Client',
      DEAL_DIRECTION: 'Direction',
      DEAL_DATE: 'Date deal',
      DEAL_TYPE: 'Deal type',
      DEAL_STATUS: 'Status',
      SUM_FROM:'Sum from',
      SUM_TO:'to',
      QUOTATION:"Quotation",
      CALCULATION:"Calculation",
      RATES:'Rates',
      COMMENT_PLACEHOLDER: 'Enter message...',
      PINNED_MESSAGES:'Pinned messages',
      LEFT:'Left',
      TOOLTIP:'Only numbers and . (without spaces)',
      EXPIRED:'Expired',
      MANAGER:'Manager',
      RESPONSIBLE:'Responsible',
      DELIVERY_TYPE:'Delivery type',
      PICKUP:'Pick up',
      INSPECTOR:'Inspector',
      BUDGET:'Budget',
      CONTRAGENT:'Contragent',
      CONTRAGENT_SUM:'Contragent sum',
      PAYMENT_DATE:'Payment date',
      PAYMENT_TYPE:'Payment type',
      NEW_REGULAR:'New/regular client',
      AFFILIATION_ORIGIN:'Affiliation origin',
      EDIT_DEAL_TITLE:'Edit deal',
      NO_DEALS:'No deals',
      FILTER_OPTIONS:'Filter options',
      CREATED:'Created',
      INVOICE:'Invoice',
      AGREEMENT:"Agreement",
      NEW_DEAL_MODAL:{
        TITLE:'New deal',
        DEAL_NAME:'Deal name'
      },
      DELETE_DEAL_MODAL:{
        TITLE:'Delete deal',
        ATTENTION: 'Attention!!',
        TEXT: 'Are you sure you want to delete this deal?',
      },
      DELETE_MODAL: {
        TITLE:'Delete record',
        ATTENTION: 'Attention!!',
        TEXT: 'Are you sure you want to delete this record?',
      },
      QUOTATION_MODAL:{
        TITLE:'New quotaiton',
        DEAL_ID:'Deal id',
        BOXES_TYPE:'Boxes type',
        BOXES_DIMENSIONS:'Boxes dimensions',
        UNITS:'Units',
        BOXES_NUMBER:'Boxes number',
        BOXES_WEIGHT:'Boxes weight',
        GOODS_NAME:'Goods name',
        VALUE:'Value',
        PICK_UP_ADDRESS:'Pick up address',
        DELIVERY_ADDRESS:'Delivery address'
      },
      INVOICE_MODAL:{
        TITLE:"Form invoice",
        DDP_DDU:"DDP/DDU",
        DESTINATION:"Destination address",
        DESTINATION_COUNTRY:"Destination country",
        RECIPIENT:"Recipient",
        DELIVERY_METHOD:"Delivery method",
        BOXES_NUMBER:"Number of boxes",
        FREIGHT:'Freight',
        INSURANCE:'Insurance',
        PICKUP:'Pick up',
        INSPECTION:'Inspection',
        WEIGHT_CBM:"Weight/CBM"
      },
      AGREEMENT_MODAL:{
        TITLE:"Form agreement",
        DATE:"Agreement date"
      },
      CALCULATION_TITLE:'New calculation',
      GALLERY:'Gallery',
      GALLERY_NO_PHOTO:'Image not found',
      DEAL:"Deal",
      CLIENT_INFO:"Client information",
      TRACKING_NUMBER:"Tracking number",
      VESSEL_NAME:"Vessel name",
      COMMENT_FOR_CLIENT:"Comment for client",
    },
    SHIPMENTS: {
      SEARCH_PLACEHOLDER: 'Search',
      ADD_CONTAINER_TITLE: 'Add container',
      ADD_CONTAINER_FORM: 'Add\'s container',
      SHIPMENT_TYPE: 'Shipment type',
      WH_DELIVERY_DATE: 'Warehouse delivery date',
      CLIENT: 'Client',
      TABLE: {
        SHIP_TYPE_TITLE: 'Type',
        CONTAINER_TITLE: 'Container number',
        PALLETS_TITLE: 'Pallet\'s count',
        DEPART_TITLE: 'ETD',
        ARRIVE_TITLE: 'ETA',
        FROM_PLACE_TITLE: 'POD',
        TO_PLACE_TITLE: 'POA',
        STATUS_TITLE: 'Status',
        TRACK_NUMBER_TITLE: 'Track number',
        CARGO_TITLE: 'Cargo',
        STORAGE_TITLE: 'Storage/Warehouse',
        CONTAINER_NAME: 'Container name',
        DELIVERY_PRICE: 'Delivery Price',
        WH_DELIVERY_DATE: 'WH Delivery date',
        EXPENCES: 'Expences',
        SHIPMENT_REF_ID: 'Shipment ref ID',
        NOTE: 'Notes',
        WAREHOUSE_TITLE: 'Warehouse',
        DELIVERY_COST_TITLE: 'Delivery cost',
        UNLOADING_TITLE: 'Unloading',
        DRAYAGE_TITLE: 'Drayage',
      },
    },
    GENERAL: {
      FILTER: {
        TITLE: 'Filter option',
        BUTTON: 'Filter',
        STATUS: 'Status:',
        MANAGER: 'Manager',
        ADVISER: 'Adviser',
        AFFILIATE_TYPE: 'Affiliate type',
        DATE_ADDED: 'Date of added',
        CLEAR: 'Clear filter',
      },
      YES: 'Yes',
      NO: 'No',
      RESET: 'Reset',
      APPLY: 'Apply',
      COMPLETE:'Complete',
      COMPLETED:'Completed',
      CANCEL: 'Cancel',
      CREATE:'Create',
      CLEAR:'Clear',
      RESULT:'Result',
      SEND:'Send',
      SAVE: 'Save',
      PLEASE_WAIT: 'Please wait',
      EXPORT: 'Export',
      SEARCH: {
        BUTTON: 'Search',
        HELP_MESSAGE: 'For search you can use Name, Phone, Email and company fields and press Enter'
      },
      ACTIONS: 'Actions',
      ACTIVE:"Active",
      INACTIVE:"Inactive",
      CHAT: 'Chat',
      MESSAGE: 'Message',
      MESSAGES: 'Messages',
      EDIT: 'Edit',
      DELETE: 'Delete',
      DATE:'Date',
      TIME:'Time',
      DEAL:'Deal',
      CHANGED:'Changed',
      YOU:'You',
      FILES:'Files',
      FILE:'File',
      TEXT:'Text',
      EMAIL:'Email',
      COMPANY:'Company',
      SELECT:'Select',
      SELECTED:'Selected',
      OUT:'Out',
      DISPLAYED:'Displayed',
      RECORDS:'Records',
      COMMENT:'Comment',
      SUM:"Sum",
      ERROR_RETRY:"Error, please try again",
      MAIN:"Main",
      CONFIRM:"Confirm",
      NOT_PAID:"Not paid",
      NOTIFICATIONS:"Notifications",
      NO_NOTIFICATIONS:"No notifications",
      LOADING:"Loading",
      NO_DATA:"No data",
    },
    JOBS: {
      CURRENT: 'Current',
      MISSED: 'Expired',
      TODAY: 'Today',
      TOMORROW: 'Tomorrow',
      AFTER_TOMORROW: 'After tomorrow',
      WEEK: 'This week',
      MONTH: 'This month',
      YEAR: 'This year',
      NEW_JOB: 'Make task',
      MY_JOBS_CHECK: 'Only my tasks',
      NOT_CHOSEN:'Not chosen',
      RESET:'Reset',
      FOR_TODAY:'For today',
      FOR_TOMORROW:'For tomorrow',
      FOR_AFTER_TOMORROW:'For after tomorrow',
      FOR_WEEK:'For week',
      FOR_MONTH:'For month',
      NO_JOBS:'No tasks',
      MODAL:{
        EDIT_TITLE: 'Edit task',
        ADD_TITLE: 'Add task',
        DELETE_TITLE: 'Delete task',
        COMPLETE_TITLE:'Complete task',
        COMMENT: 'Comment',
        RESPONSIBLE:'Who is responsible',
        JOB_TYPE:'Task type',
        JOB_RESULT:'Task result'
      },
      DELETE_MODAL: {
        CARD_DELETE_ATTENTION: 'Attention!!',
        CARD_DELETE_TEXT: 'Are you sure you want to delete this task?',
      },
    },
    USER:{
      USER:'User',
      TOOLTIP:"Reset and send password by email",
      MANAGER:"Manager",
      YOUR_MANAGER:"Your manager",
      DEALS_SUM:"Deals sum",
      DEAL_SUM:"Deal sum",
      DEALS:"Deals",
      DEAL:"Deal",
      SETTINGS:"Settings",
      INFO:"Information",
      PROFILE_DETAILS:"Profile details",
      NAME:"Name",
      NAME_EN:"Name English",
      STATUS:"Status",
      STYLE:"Style",
      PHONE:"Phone",
      EMAIL:"Email",
      COMPANY:'Company name',
      BRAND:"Brand",
      ADDRESS:"Address",
      PROFILE_SETTINGS:"Profile settings",
      AVATAR:'Avatar',
      FULL_NAME:"Full name",
      FILES:"Files",
      LOAD_FILES:"Load files",
      VESSEL_INFO:"Vessel information",
      TRACKING:"Tracking numbers",
      SHIPMENT_PROGRESS:"Shipment progress",
      SHIPMENT_DETAILS:"Shipment details",
      SECURITY:{
        SECURITY:'Security',
        CHANGE_PASSWORD:"Change password",
      },
      TRANSFER:"Transfer all to ",
      SEND_REGISTRATION:"Send password",
      EMAIL_MODAL:{
        TITLE:"Reset password",
        TEXT:"You are going to reset password and send it by email to user"
      }
    },
    BOARD: {
      GENERAL: {
        TITLE: 'Board reminds',
        YET_COLUMN_TITLE: 'Created' ,
        IN_PROGRESS_COLUMN_TITLE: 'In progress' ,
        COMPLETE_COLUMN_TITLE: 'Complete' ,
        ARCHIVE_COLUMN_TITLE: 'Archive' ,
        NEW_CARD_TITLE: 'New remind',
        NO_SELECTED_TITLE: 'No selected',
      },
      MODAL: {
        TITLE: 'Add / edit card',
        MEMBERS_TITLE: 'Members',
        EMPLOYEES_TITLE: 'Employees',
        SEARCH_PLACEHOLDER: 'Search users',
        ADD_MARKS: 'Add marks',
        MARKS_TITLE: 'Marks',
        NOTIFY_TITLE: 'Notify',
        NOTIFY_TITLE_ON: 'Notify On',
        NOTIFY_TITLE_OFF: 'Notify Off',
        ADD_TO_CARD_TITLE: 'Add to card',
        DATE_TIME_TITLE: 'Date / Time',
        SUBJECT_TITLE: 'Title',
        DESCRIPTION_TITLE: 'Description',
        FILES_TITLE: 'Files',
        COMMENT_TITLE: 'Actions / Comments',
        COMMENT_PLACEHOLDER: 'Enter message...',
        DATE_TITLE: 'Date',
        ARCHIVE: 'Archive',
        DELETE: 'Delete',
      },
      DELETE_MODAL: {
        CARD_DELETE_TITLE: 'Delete card',
        CARD_DELETE_ATTENTION: 'Attention!!',
        CARD_DELETE_TEXT: 'Are you sure you want to delete this card?',
      },
    },
    FOOTER: {
      TITLE: '',
      ABOUT: 'About',
      CONTACT: 'Contact',
      CHANGE: 'Change Log'
    },
    CHANGE_LOG: {
      TITLE: 'Journal of updates, edits and improvements',
      NEW_CHANGELOG:"New changelog",
      NEW_CHANGELOG_TITLE:"Add new changelog",
      EDIT_CHANGELOG_TITLE:"Edit changelog",
      DELETE_CHANGELOG_TITLE:"Delete changelog",
      VERSION:"Version",
      DESCRIPTION:"Description"
    },
    PRODUCTS: {
      MENU_TITLE: 'Products',
      SEARCH_DESC: 'Search products'
    },
    REPORTS: {
      PAGE_TITLE: 'Clients report',
      SEARCH_DESC: 'Search reports',
      EXPORT_FORM_TITLE: 'Export clients data to file',
      EXPORT_FORM_DESC: 'Export clients data to file',
      EXPORT_FORM_BUTTON: 'Export',
      EXPORT_FORM_BUTTON_WAIT: 'Exporting...',
      EXPORT_FORM_BUTTON_SUCCESS: 'Export completed',
      EXPORT_FORM_BUTTON_ERROR: 'Export error',
      FORM_HEADER_CLIENT_NAME: 'Client',
      FORM_HEADER_AFFILIATE_TYPE: 'Affiliate type',
      FORM_HEADER_DATE: 'Date added',
      FORM_HEADER_MANAGER: 'Manager',
      FORM_HEADER_STATUS: 'Status',
      FORM_HEADER_DEAL_COUNT: 'Deals count',
      FORM_HEADER_DETAILS: 'Details',
    }
  }
};
