import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsReadCommentsPipe } from '../is-read-comments.pipe';
import {GetClientOptionPipe} from "../get-client-option.pipe";
import {GetManagerNamePipe} from "../get-manager-name.pipe";
import { GetUserPicPipe } from '../get-user-pic.pipe';
import {SearchInArrayObjectsPipe} from "../search-in-array-objects.pipe";
import {TimeDifferencePipe} from "../time-difference.pipe";
import {UserAvatarPipe} from "../user-avatar.pipe";


@NgModule({
  declarations: [
    GetManagerNamePipe,
    IsReadCommentsPipe,
    GetClientOptionPipe,
    GetUserPicPipe,
    SearchInArrayObjectsPipe,
    TimeDifferencePipe,
    UserAvatarPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    GetManagerNamePipe,
    IsReadCommentsPipe,
    GetClientOptionPipe,
    GetUserPicPipe,
    SearchInArrayObjectsPipe,
    TimeDifferencePipe,
    UserAvatarPipe
  ]
})
export class SharedPipesModule { }
