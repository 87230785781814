//UA
export const locale = {
  lang: 'ua',
  data: {
    TRANSLATOR: {
      SELECT: 'Оберіть свою мову',
    },
    MENU: {
      NEW: 'Новий',
      ACTIONS: 'Дії',
      CREATE_POST: 'Створить новий пост',
      PAGES: 'Сторінки',
      FEATURES: 'Розробки',
      APPS: 'Програми',
      DASHBOARD: 'Дашборд',
      CLIENTS: 'Клієнти',
      DEALS: 'Угоди',
      DOCS: 'Документи',
      INVOICES: 'Інвойси',
      USERS: 'Користувачи',
      ROLES: 'Ролі',
      SETTINGS: 'Налаштування',
      SETTINGS_SYS: 'Налаштування сістемы',
      CLIENT_DEALS: 'Мої угоди',
      JOBS: 'Завдання',
      TASKS:'Задачі',
      FILES:'Файли',
      BOARD: 'Дошка',
      TOOLS: {
        TITLE: 'Інструменти',
        INSPECTION_TOOL: 'Калькулятор інспекції'
      },
      REPORTS: {
        TITLE: "Звіти",
        CLIENTS_REPORT: "Клієнти",
        DEALS_REPORT: "Угоди",
      }

    },
    APP_USER_MENU: {
      PROFILE: 'Профіль',
      DEALS: 'Мої угоди',
      LANGUAGE: 'Мова',
      LOGOUT: 'Вийти',
    },
    AUTH: {
      GENERAL: {
        OR: 'Або',
        SUBMIT_BUTTON: 'Відправити',
        NO_ACCOUNT: 'В вас ще немає акаунта?',
        SIGNUP_BUTTON: 'Реєстрація',
        FORGOT_BUTTON: 'Забули пароль',
        BACK_BUTTON: 'Назад',
        PRIVACY: 'Конфіденційність',
        LEGAL: 'Права',
        CONTACT: 'Контакти',
      },
      LOGIN: {
        TITLE: 'Вхід в систему',
        BUTTON: 'Вхід',
        TITLE_TEXT: 'Ваш персональний обліковий запис',
      },
      FORGOT: {
        TITLE: 'Забули пароль?',
        DESC: 'Введіть свій e-mail',
        SUCCESS: 'Ваш аккаунт успішно відновлен.'
      },
      REGISTER: {
        TITLE: 'Реєстрація',
        DESC: 'Введіть ваші дані для створення акаунта',
        SUCCESS: 'Ваш акаунт успішно зареєстрований.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Ваше Ім\'я',
        PASSWORD: 'Пароль',
        CONFIRM_PASSWORD: 'Підтвердіть пароль',
        USERNAME: 'Ім\'я користувача'
      },
      VALIDATION: {
        INVALID: '{{name}} не корректное',
        REQUIRED: '{{name}} обязательно',
        MIN_LENGTH: '{{name}} минимальная длинна {{min}}',
        AGREEMENT_REQUIRED: 'Необходимо принять условия',
        NOT_FOUND: 'Запрашивамая {{name}} не найдена',
        INVALID_LOGIN: 'Не вірні дані',
        REQUIRED_FIELD: "Обов'язкове поле",
        MIN_LENGTH_FIELD: 'Минимальная длинна:',
        MAX_LENGTH_FIELD: 'Масимальная длинна:',
        INVALID_FIELD: 'Поле не корректное',
        INVALID_EMAIL:'Не вірний email'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Количество выбранных записей: ',
        ALL: 'Все',
        SUSPENDED: 'Приостановленный',
        ACTIVE: 'Активный',
        FILTER: 'Фильтр',
        BY_STATUS: 'по Статусу',
        BY_TYPE: 'По типу',
        BUSINESS: 'Бизнесс',
        INDIVIDUAL: 'Индивидуальный',
        SEARCH: 'Поиск',
        IN_ALL_FIELDS: 'во всех полях'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Клиенты',
        CUSTOMERS_LIST: 'Список клиентов',
        NEW_CUSTOMER: 'Новый клиент',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Удаление клиента',
          DESCRIPTION: 'Вы уверены что мгновенно хотите удалить клиента?',
          WAIT_DESCRIPTION: 'Удаление клиента...',
          MESSAGE: 'Клиент удален'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Удаление клиентов',
          DESCRIPTION: 'Вы уверены что хотите удалить выбранных клиентов?',
          WAIT_DESCRIPTION: 'Удаление клиентов...',
          MESSAGE: 'Выбранные клиенты удалены'
        },
        UPDATE_STATUS: {
          TITLE: 'Обновлен статус для выбранных клиентов',
          MESSAGE: 'Статус выбранных клиентов успешно обновлен'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Клиент обновлен',
          ADD_MESSAGE: 'Клиент успешно создан'
        }
      }
    },
    CLIENTS: {
      SEARCH_CUSTOMERS: 'Пошук клієнтів',
      ADD_CUSTOMER: 'Додати',
      CUSTOMER_NAME: 'Ім\'я клієнта',
      CUSTOMER_EMAIL: 'E-mail',
      CUSTOMER_COMPANY: 'Компанія',
      CUSTOMER_PHONE: 'Телефон',
      CUSTOMER_STATUS: 'Статус валідації',
      CUSTOMER_DETAIL: 'Клієнт',
      CUSTOMER_INFO_TAB: 'Деталі',
      CUSTOMER_DEALS_TAB: 'Угоди',
      CUSTOMER_ACTIVITY_TAB: 'Активність',
      CUSTOMER_ADD_DEAL: 'Угода',
      CUSTOMER_ADD_JOB: 'Задача',
      QUOTATION:"Розрахунок",
      CUSTOMER_WORK_REGION: 'Регіон',
      CUSTOMER_ADDRESS: 'Штат / Область / Місто / Адреса',
      CLIENTS_TAB_SHIPMENTS: 'Надсилання',
      CLIENTS_TAB_INFO: 'Інфо',
      FORM:{
        CLIENT_EDIT_TITLE: 'Додавання / редагування клієнта',
        CLIENT_AVATAR: 'Фото/Аватар',
        CLIENT_F_L_NAME: 'Прізвище ім\'я',
        CLIENT_ADDITIONAL_PHONE: 'Додатковий телефон',
        CLIENT_MANAGER: 'Менеджер',
        CLIENT_ADVISER: 'Відповідальний',
        CLIENT_AFFILIATE_TYPE: 'Звідки прийшов',
        CLIENT_POSITION: 'Посада',
        CLIENT_ADDITIONAL_INFO: 'Додаткова інформація (будь-який текст не вказаний у полях вище)'
      },
      TABS_DETAILS:{
        WORK_STATUS: 'Статус роботи',
        FB_STATUS: 'Фейсбук статус',
        POSITION: 'Бренд',
        COMPANY: 'Компанія',
        REF_PATH: 'Звідки прийшов',
        DELIVERY: 'З ким возить',
        PARTNER_FB: 'Facebook партнер',
        MANAGER: 'Менеджер',
        ADVISER: 'Відповідальний',
        TELEGRAM:'Телеграм',
      },
      NO_FILES:'Немає файлів',
      DELETE_CLIENT: {
        TITLE: 'Видалити клієнта',
        ATTENTION: 'Увага!!',
        TEXT: 'Ви впевнені що хочете видалили цього клієнта?',
      },
    },
    DEALS: {
      SEARCH_DEAL: 'Пошук угод',
      DEAL_ADD: 'Додати угоду',
      DEAL_ID_OR_NAME: 'Ім\'я/Номер угоди',
      DEAL_ID: 'Номер угоди',
      DEAL_CLIENT_NAME: 'Клієнт',
      DEAL_DIRECTION: 'Напрямок',
      DEAL_DATE: 'Дата угоди',
      DEAL_TYPE: 'Тип угоди',
      DEAL_STATUS: 'Статус',
      SUM_FROM:'Сума з',
      SUM_TO:'на',
      QUOTATION:"Запит",
      CALCULATION:"Розрахунок",
      RATES:'Ставки',
      COMMENT_PLACEHOLDER: 'Введіть данні...',
      PINNED_MESSAGES:'Закріплений запис',
      LEFT:'Залишилося',
      TOOLTIP:'Тільки цифри та . (без пробілів)',
      EXPIRED:'Просрочена',
      MANAGER:'Менеджер',
      RESPONSIBLE:'Відповідальний',
      DELIVERY_TYPE:'Тип доставки',
      PICKUP:'Pick up',
      INSPECTOR:'Інспектор',
      BUDGET:'Бюджет',
      CONTRAGENT:'Котрагент',
      CONTRAGENT_SUM:'Сума котрагента',
      PAYMENT_DATE:'Дата оплати',
      PAYMENT_TYPE:'Вид оплати',
      NEW_REGULAR:'Новий/постійний клієнт',
      AFFILIATION_ORIGIN:'Звідки прийшов',
      EDIT_DEAL_TITLE:'Редагувати угоду',
      NO_DEALS:'Немає угод',
      FILTER_OPTIONS:'Опції фільтра',
      CREATED:'Створено',
      INVOICE:'Інвойс',
      AGREEMENT:"Договір",
      NEW_DEAL_MODAL:{
        TITLE:'Нова угода',
        DEAL_NAME:"Ім'я угоди"
      },
      DELETE_DEAL_MODAL:{
        TITLE:'Видалити угоду',
        ATTENTION: 'Увага!!',
        TEXT: 'Ви впевнені що хочете видалити цю угоду?',
      },
      DELETE_MODAL: {
        TITLE:'Видалити запис',
        ATTENTION: 'Увага!!',
        TEXT: 'Ви впевнені що хочете видалити цей запис?',
      },
      QUOTATION_MODAL:{
        TITLE:'Новий запит',
        DEAL_ID:'Номер угоди',
        BOXES_TYPE:'Видів коробок',
        BOXES_DIMENSIONS:'Розміри коробок',
        UNITS:'Одиниці',
        BOXES_NUMBER:'Кількість коробок',
        BOXES_WEIGHT:'Вага коробок',
        GOODS_NAME:'Назва товару',
        VALUE:'Ціна',
        PICK_UP_ADDRESS:'Адреса постачальника',
        DELIVERY_ADDRESS:'Адреса доставки'
      },
      INVOICE_MODAL:{
        TITLE:"Створити інвойс",
        DDP_DDU:"DDP/DDU",
        DESTINATION:"Адреса доставки",
        DESTINATION_COUNTRY:"Країна призначеняя",
        RECIPIENT:"Отримувач",
        DELIVERY_METHOD:"Метод відправки",
        BOXES_NUMBER:"Кількість коробок",
        FREIGHT:'Фрахт',
        PICKUP:'Pick up',
        INSURANCE:'Страхування',
        INSPECTION:'Інспекція',
        WEIGHT_CBM:"Вага/CBM"
      },
      AGREEMENT_MODAL:{
        TITLE:"Створити договір",
        DATE:"Дата договору"
      },
      CALCULATION_TITLE:'Новий розрахунок',
      GALLERY:'Галерея',
      GALLERY_NO_PHOTO:'Зображення не знайдено'
    },
    SHIPMENTS: {
      SEARCH_PLACEHOLDER: 'Пошук',
      ADD_CONTAINER_TITLE: 'Додати контейнер',
      ADD_CONTAINER_FORM: 'Додавання контейнера',
      SHIPMENT_TYPE: 'Тип доставки',
      WH_DELIVERY_DATE: 'Дата прибуття на склад',
      CLIENT: 'Клієнт',
      TABLE: {
        SHIP_TYPE_TITLE: 'Тип',
        CONTAINER_TITLE: 'Номер контейнеру',
        PALLETS_TITLE: 'Кіл-ть палет',
        DEPART_TITLE: 'Дата виходу',
        ARRIVE_TITLE: 'Дата прибуття',
        FROM_PLACE_TITLE: 'Місце виходу',
        TO_PLACE_TITLE: 'Місце прибуття',
        STATUS_TITLE: 'Статус',
        TRACK_NUMBER_TITLE: 'Номер відстеження',
        CARGO_TITLE: 'Склад вантажу',
        STORAGE_TITLE: 'Місце зберігання',
        CONTAINER_NAME: 'Назва контейнера',
        DELIVERY_PRICE: 'Вартість доставки',
        WH_DELIVERY_DATE: 'Дата прибуття на склад',
        EXPENCES: 'Витрати',
        SHIPMENT_REF_ID: 'Shipment ref ID',
        NOTE: 'Нотатки',
        WAREHOUSE_TITLE: 'Зберігання',
        DELIVERY_COST_TITLE: 'Витрати доставки',
        UNLOADING_TITLE: 'Розвантаження',
        DRAYAGE_TITLE: 'Транспортування',
      },
    },
    GENERAL: {
      FILTER: {
        TITLE: 'Опції фільтра',
        BUTTON: 'Фільтр',
        STATUS: 'Статус:',
        MANAGER: 'Менеджер',
        ADVISER: 'Відповідальний',
        AFFILIATE_TYPE: 'Звідки прийшов',
        CLEAR: 'Очистити фільтр',
      },
      CANCEL: 'Скасувати',
      SAVE: 'Зберегти',
      YES: 'Так',
      NO: 'Ні',
      RESET: 'Скинути',
      APPLY: 'Застосувати',
      COMPLETE:'Завершити',
      COMPLETED:'Завершено',
      CREATE:'Створити',
      CLEAR:'Очистити',
      RESULT:'Результат',
      SEND:'Надіслати',
      EXPORT: 'Експорт',
      SEARCH: {
        BUTTON: 'Пошук',
        HELP_MESSAGE: 'Для пошуку можна використовувати ім\'я, телефон, email, компанію та натиснути Enter'
      },
      ACTIONS: 'Дії',
      CHAT: 'Чат',
      MESSAGE: 'Повідомлення',
      MESSAGES: 'Сообщения',
      EDIT: 'Редагувати',
      DELETE: 'Видалити',
      DATE:'Дата',
      TIME:'Час',
      DEAL:'Угода',
      CHANGED:'Змінено',
      YOU:'Ви',
      FILES:'Файли',
      FILE:'Файл',
      TEXT:'Текст',
      EMAIL:'Пошта',
      COMPANY:'Компанія',
      SELECT:'Вибрати',
      SELECTED:'Вибрано',
      OUT:'з',
      DISPLAYED:'Відображено',
      RECORDS:'записів',
      COMMENT:'Коментар',
      SUM:"Сума",
      MAIN:"Головна",
      CONFIRM:"Підтвердити",
      NOT_PAID:"Не оплачено",
      NOTIFICATIONS:"Сповіщення",
      NO_NOTIFICATIONS:"Немає сповіщень",
      LOADING:"Завантаження",
      NO_DATA:"Немає даних",
    },
    JOBS: {
      CURRENT: 'Поточні',
      MISSED: 'Прострочені',
      TODAY: 'Сьогодні',
      TOMORROW: 'Завтра',
      AFTER_TOMORROW: 'Післязавтра',
      WEEK: 'Тиждень',
      MONTH: 'Місяць',
      YEAR: 'Рік',
      NEW_JOB: 'Створити задачу',
      MY_JOBS_CHECK: 'Тільки мої задачі',
      NOT_CHOSEN:'Не вибрано',
      RESET:'Скинути',
      FOR_TODAY:'На сьогодні',
      FOR_TOMORROW:'На завтра',
      FOR_AFTER_TOMORROW:'На післязавтра',
      FOR_WEEK:'На тиждень',
      FOR_MONTH:'На місяць',
      NO_JOBS:'Немає задач',
      MODAL:{
        EDIT_TITLE: 'Редагувати задачу',
        ADD_TITLE: 'Додати задачу',
        DELETE_TITLE: 'Видалити задачу',
        COMPLETE_TITLE:'Завершити задачу',
        COMMENT: 'Коментар',
        RESPONSIBLE:'На кого задача',
        JOB_TYPE:'Тип задачі',
        JOB_RESULT:'Результат задачі'
      },
      DELETE_MODAL: {
        CARD_DELETE_ATTENTION: 'Увага!!',
        CARD_DELETE_TEXT: 'Ви впевнені що хочете видалити задачу?',
      },
    },
    USER:{
      USER:'Користувач',
      TOOLTIP:"Скинути пароль і відправити по пошті",
      MANAGER:"Менеджер",
      YOUR_MANAGER:"Ваш менеджер",
      DEALS_SUM:"Сума угод",
      DEAL_SUM:"Кількість угод",
      DEALS:"Угоди",
      DEAL:"Угода",
      SETTINGS:"Налаштування",
      INFO:"Інформація",
      PROFILE_DETAILS:"Деталі профіля",
      NAME:"Ім'я",
      PHONE:"Телефон",
      EMAIL:"Email",
      COMPANY:'Назва компанії',
      BRAND:"Бренд",
      ADDRESS:"Адреса",
      PROFILE_SETTINGS:"Налаштування профіля",
      AVATAR:'Фото',
      FULL_NAME:"Повне ім'я",
      FILES:"Файли",
      LOAD_FILES:"Завантажити файли",
      VESSEL_INFO:"Назва судна",
      TRACKING:"Трек номер",
      SHIPMENT_PROGRESS:"Прогрес відправки",
      SHIPMENT_DETAILS:"Деталі відправки",
      SECURITY:{
        SECURITY:'Безпека',
        CHANGE_PASSWORD:"Змінити пароль",
      },
      TRANSFER:"Перенести все на ",
      SEND_REGISTRATION:"Відправити пароль",
      EMAIL_MODAL:{
        TITLE:"Скинути пароль",
        TEXT:"Ви збираєтеся скинути пароль і відправити його на пошту користувача"
      }
    },
    BOARD: {
      GENERAL: {
        TITLE: 'Дошка нагадувань',
        YET_COLUMN_TITLE: 'Створено' ,
        IN_PROGRESS_COLUMN_TITLE: 'В процесі' ,
        COMPLETE_COLUMN_TITLE: 'Завершено' ,
        ARCHIVE_COLUMN_TITLE: 'Архів' ,
        NEW_CARD_TITLE: 'Нове нагадування',
        NO_SELECTED_TITLE: 'Не вибрано',
      },
      MODAL: {
        TITLE: 'Додати / редагувати нагадування',
        MEMBERS_TITLE: 'Користувачі',
        EMPLOYEES_TITLE: 'Співробітники',
        SEARCH_PLACEHOLDER: 'Пошук користувачів',
        ADD_MARKS: 'Додати теги',
        MARKS_TITLE: 'Теги',
        NOTIFY_TITLE: 'Повідомлення',
        NOTIFY_TITLE_ON: 'Повідомлення Увім.',
        NOTIFY_TITLE_OFF: 'Повідомлення Вимк.',
        ADD_TO_CARD_TITLE: 'Додати на картку',
        DATE_TIME_TITLE: 'Дата / Час',
        SUBJECT_TITLE: 'Заголовок',
        DESCRIPTION_TITLE: 'Опис',
        FILES_TITLE: 'Вкладення',
        COMMENT_TITLE: 'Дії / Коментарі',
        COMMENT_PLACEHOLDER: 'Введіть повідомлення...',
        DATE_TITLE: 'Дата',
        ARCHIVE: 'Архів',
        DELETE: 'Видалити',
      },
      DELETE_MODAL: {
        CARD_DELETE_TITLE: 'Видалити нагадування',
        CARD_DELETE_ATTENTION: 'Увага!!',
        CARD_DELETE_TEXT: 'Ви впевнені, що хочете видалити нагадування?',
      },
    },
    FOOTER: {
      TITLE: '',
      ABOUT: 'Про нас',
      CONTACT: 'Контакти',
      CHANGE: 'Журнал змін'
    },
    CHANGE_LOG: {
      TITLE: 'Журнал оновлень, правок та доопрацювань',
      NEW_CHANGELOG:"Новий список оновлень",
      NEW_CHANGELOG_TITLE:"Додати новий список оновлень",
      EDIT_CHANGELOG_TITLE:"Редагувати список оновлень",
      DELETE_CHANGELOG_TITLE:"Видалити список оновлень",
      VERSION:"Версія",
      DESCRIPTION:"Опис"
    },
    PRODUCTS: {
      MENU_TITLE: 'Товари',
      SEARCH_DESC: 'Пошук товара'
    },
    REPORTS: {
      PAGE_TITLE: 'Звіти щодо клієнтів',
      SEARCH_DESC: 'Пошук',
      EXPORT_FORM_TITLE: "Експорт клієнтів у файл",
      EXPORT_FORM_DESC: 'Експорт даних клієнтів у файл',
      EXPORT_FORM_BUTTON: 'Експортувати',
      EXPORT_FORM_BUTTON_WAIT: 'Експорт...',
      EXPORT_FORM_BUTTON_SUCCESS: 'Експорт завершено',
      EXPORT_FORM_BUTTON_ERROR: 'Помилка експорту',
      FORM_HEADER_CLIENT_NAME: "Клієнт",
      FORM_HEADER_AFFILIATE_TYPE: 'Звідки прийшов',
      FORM_HEADER_DATE: 'Дата додав.',
      FORM_HEADER_MANAGER: 'Менеджер',
      FORM_HEADER_STATUS: 'Статус',
      FORM_HEADER_DEAL_COUNT: 'Кільк. угод',
      FORM_HEADER_DETAILS: 'Деталі',
    }
  }
};
