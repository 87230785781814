import { Routes } from '@angular/router';

const Routing: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'builder',
    loadChildren: () =>
      import('./builder/builder.module').then((m) => m.BuilderModule),
  },
  {
    path: 'crafted/pages/profile',
    loadChildren: () =>
      import('../modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'crafted/account',
    loadChildren: () =>
      import('../modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'crafted/pages/wizards',
    loadChildren: () =>
      import('../modules/wizards/wizards.module').then((m) => m.WizardsModule),
  },
  {
    path: 'crafted/widgets',
    loadChildren: () =>
      import('../modules/widgets-examples/widgets-examples.module').then(
        (m) => m.WidgetsExamplesModule
      ),
  },
  {
    path: 'apps/chat',
    loadChildren: () =>
      import('../modules/apps/chat/chat.module').then((m) => m.ChatModule),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./clients/clients.module').then((m) => m.ClientsModule),
  },
  {
    path: 'deals',
    loadChildren: () =>
      import('./deals/deals.module').then((m) => m.DealsModule),
  },
  {
    path: 'client-deals',
    loadChildren: () =>
      import('./client-deals/client-deals.module').then((m) => m.ClientDealsModule)
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('./jobs/jobs.module').then((m) => m.JobsModule)
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./documents/invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./roles/roles.module').then((m) => m.RolesModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'shipments',
    loadChildren: () =>
      import('./shipments/shipments.module').then((m) => m.ShipmentsModule)
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'containers',
    loadChildren: () =>
      import('./containers/containers.module').then((m) => m.ContainersModule)
  },
  {
    path: 'inspection-calc',
    loadChildren: () =>
      import('./tools/inspection-calculator/inspection.module').then((m) => m.InspectionModule)
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./tools/activity/activity.module').then((m) => m.Activity)
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./info/change-log/change-log.module').then((m) => m.ChangeLogModule)
  },
  {
    path: 'board',
    loadChildren: () =>
      import('./board/board.module').then((m) => m.BoardModule)
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
