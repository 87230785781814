import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, Subject, takeUntil} from "rxjs";
import {ActivityRequest, ToolsService} from "../../../../../services/tools.service";
import {UserType} from "../../../../../modules/auth";

@Component({
  selector: 'app-activity-drawer',
  templateUrl: './activity-drawer.component.html',
})
export class ActivityDrawerComponent implements OnInit {
  activitySubject: BehaviorSubject<ActivityItem>;
  activity$: Observable<any>;
  destroy$: Subject<void> = new Subject<void>();
  constructor(private toolsService: ToolsService) {
    this.activitySubject = new BehaviorSubject<any>(undefined);
    this.activity$ = this.activitySubject.asObservable();
  }

  ngOnInit(): void {
    // console.log('drawer already initialed')

    // this.getLogs().pipe(takeUntil(this.destroy$)).subscribe(activity => {
    //   this.activitySubject.next(activity.data);
    // })
  }

  getLogs(options?: ActivityRequest):Observable<any>{
    return this.toolsService.getActivityLogs(options);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface ActivityItem {
  id: number,
  user_id: number,
  user_name: UserType,
  user_avatar?: string,
  event: string,
  modified?: [],
  created_at: string,
  entity: string,
  entity_id: number,
  entity_name: string
}


