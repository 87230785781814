import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../modules/auth";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DealJobsService {
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.initService();
  }

  initService() {
    const authData = this.auth.getAuthLocal();
    if (!authData || !authData.token) {
      return of(undefined);
    }

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authData.token}`,
    });
  }

  getDealJobs(deal_id:number):Observable<any>{
    return this.http.get(`${environment.apiUrl}/deals/${deal_id}/jobs`, {
      headers: this.httpHeaders,
    });
  }

  // getAllJobs(options:any = []):Observable<any>{
  //   return this.http.get(`${environment.apiUrl}/jobs/all`, {
  //     headers: this.httpHeaders,
  //   });
  // }

  newDealJob(data:any):Observable<any>{
    return this.http.post(`${environment.apiUrl}/deals/new_job`, data, {
      headers: this.httpHeaders,
    });
  }

  editDealJob(data:any, id:number):Observable<any>{
    return this.http.post(`${environment.apiUrl}/deals/edit_job/${id}`, data, {
      headers: this.httpHeaders,
    });
  }

  editGroupJobs(data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/jobs/edit-group`, data, {
      headers: this.httpHeaders,
    });
  }

  completeDealJob(data:any, id:number):Observable<any>{
    return this.http.post(`${environment.apiUrl}/deals/complete_job/${id}`, data, {
      headers: this.httpHeaders,
    });
  }

  removeDealJob(id:number):Observable<any>{
    return this.http.get(`${environment.apiUrl}/deals/remove_job/${id}`, {
      headers: this.httpHeaders,
    });
  }

  getDealJobsByManagerId(manager_id:number | undefined):Observable<any>{
    return this.http.get(`${environment.apiUrl}/jobs/${manager_id}`, {
      headers: this.httpHeaders,
    });
  }

  getDealJobComments(job_id:number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/jobs/job-comments/${job_id}`, {
      headers: this.httpHeaders,
    });
  }

  addJobComment(data:any, job_id:number):Observable<any>{
    return this.http.post(`${environment.apiUrl}/jobs/job-comments/${job_id}`, data, {
      headers: this.httpHeaders,
    });
  }

  markCommentIsRead(job_id: number):Observable<any>{
    return this.http.get(`${environment.apiUrl}/jobs/job-comments-mark/${job_id}`, {
      headers: this.httpHeaders,
    });
  }
}
