import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthService} from "../modules/auth";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.initService();
  }

  initService() {
    const authData = this.auth.getAuthLocal();
    if (!authData || !authData.token) {
      return of(undefined);
    }

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authData.token}`,
    });
  }

  getActivityLogs(options?: ActivityRequest): Observable<any> {
    let params = new HttpParams();

    if (options) {
      Object.keys(options).forEach((key) => {
        const value = (options as any)[key];
        if (value !== undefined && value !== null) {
          params = params.set(key, value);
        }
      });
    }

    return this.http.get(`${environment.apiUrl}/audit/logs`, {
      headers: this.httpHeaders,
      params: params,
    });
  }



  getChangelogs():Observable<any>{
    return this.http.get(`${environment.apiUrl}/tools/changelogs`, {
      headers: this.httpHeaders,
    });
  }
  newChangelog(data: any):Observable<any>{
    return this.http.post(`${environment.apiUrl}/tools/changelogs`, data,{
      headers: this.httpHeaders,
    });
  }
  editChangelog(id:number,data: any):Observable<any>{
    return this.http.put(`${environment.apiUrl}/tools/changelogs/${id}`, data,{
      headers: this.httpHeaders,
    });
  }
  deleteChangeLog(id:number):Observable<any>{
    return this.http.delete(`${environment.apiUrl}/tools/changelogs/${id}`,{
      headers: this.httpHeaders,
    });
  }
}

export interface ActivityRequest {
  currentPage: number,
  perPage: number,
  userId: number,
  recordType: string,
  event: string,
  fromDate: string,
  toDate: string,
}
